import React from 'react'
import { Box, styled, Typography } from '@mui/material'

const Container = styled(Box)(() => ({
    height: 'calc(100vh - 64px)',
    minHeight: 300,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
}))
const Title = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    fontSize: '8rem',
    [theme.breakpoints.only('xs')]: {
        fontSize: '4rem',
    },
})) as typeof Typography
const PushLine = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    fontSize: '1.9rem',
    [theme.breakpoints.only('xs')]: {
        fontSize: '1.5rem',
    },
})) as typeof Typography

const HomeView: React.FC = () => {
    return (
        <Container>
            <Title component="h1">Famiz</Title>
            <PushLine component="h2">Solutions pour la famille</PushLine>
        </Container>
    )
}

export default HomeView
