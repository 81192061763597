import * as React from 'react'
import { ComponentType, useEffect } from 'react'
import { navigate } from 'gatsby'
import { WithAuthenticationProps } from './withAuthentication'

const withValidatedEmail = (ComposedComponent: ComponentType<WithAuthenticationProps>) => {
    const WithValidatedEmail: React.FC<WithAuthenticationProps> = ({ user, isLoading, ...props }) => {
        useEffect(() => {
            if (!isLoading && user && !user.hasEmailValidated) {
                navigate('/mon-compte/validation-email.html')
            }
        }, [isLoading, user])

        return <ComposedComponent user={user} isLoading={isLoading} {...props} />
    }
    return WithValidatedEmail
}

export default withValidatedEmail
