import React from 'react'
import { RouteComponentProps } from '@reach/router'
import HomeView from 'components/views/homeView'
import Main from './common/main'
import { graphql } from 'gatsby'
import withValidatedEmail from './hoc/withValidatedEmail'
import withAuthentication, { WithAuthenticationProps } from './hoc/withAuthentication'

interface Props extends RouteComponentProps, WithAuthenticationProps {}

const Home: React.FC<Props> = () => {
    return (
        <Main>
            <HomeView />
        </Main>
    )
}

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`

export default withAuthentication(withValidatedEmail(Home))
